@media only screen and (max-width: 480px) {
  ::-webkit-scrollbar {
    width: 0;
  }

  .hometitle {
    margin: 36px auto 18px;
    font-size: 42px;
  }

  .omnibox {
    margin: 0 16px 16px 16px;
    max-width: calc(100% - 32px);
  }

  .footermiddle {
    margin: 0;
    margin-left: auto;
    margin-right: 10px;
  }

  .footer div:nth-child(3) {
    display: none;
  }

  .itemscontainer {
    padding: 0;
  }

  .gitems {
    grid-template-columns: repeat(auto-fill, 120px);
  }

  .gitem {
    height: 120px;
    width: 120px;
  }

  .gtext {
    width: 120px;
  }

  .settingssidetitle {
    display: none;
  }

  .settingssidebar {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .settingssidecard {
    border-radius: var(--border-radius);
    padding: 14px 18px;
    height: auto;
    border: 0;
    margin: 10px 0;
  }

  .settingsapp {
    width: 100%;
    top: calc(60px + 76px);
    height: calc(100% - calc(60px + 76px));
  }

  .chooseoption {
    max-width: 100%;
  }

  .optionchooseinput {
    width: calc(100% - 60px);
  }

  .suggestions {
    margin: 0 16px;
    max-width: calc(100% - 32px);
  }
}
