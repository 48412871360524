* {
  font-family: var(--font-main);
}

body {
  margin: 0;
  padding: 0;
  background: var(--background);
  color: var(--text);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root::before {
  content: '';
  display: block;
  width: 100%;
  height: 60px;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-thumb {
  background: var(--highlight);
  border: 4px solid transparent;
  background-clip: content-box;
  border-radius: 8px;
}

::placeholder {
  color: var(--placeholder);
}

a {
  text-decoration: none;
}

.hometitle {
  font-family: var(--font-title) !important;
  font-size: 55px;
  text-align: center;
  font-weight: bold;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: flex-end;
  min-height: 92px;
}

.omniboxcontainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
}

.omnibox {
  border: none;
  width: 584px;
  height: 44px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--searchbox);
  margin: 20px 10px 40px 10px;
  max-width: calc(100% - 10px);
}

.omniboxcontainersearch {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: auto 0;
  background: var(--background);
  z-index: 8;
  padding-top: 60px;
}

.omnisearch {
  margin: 10px;
  width: 690px;
}

.search {
  height: 44px;
  outline: none;
  background: transparent;
  color: var(--text-contrast);
  font-size: large;
  border: none;
  position: absolute;
  right: 7px;
  left: 44px;
}

.navitems {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.navitem {
  height: 40px !important;
  width: 40px !important;
  cursor: pointer;
  color: var(--text-contrast);
  text-decoration: none;
  border-radius: var(--border-radius-round);
  background: var(--highlight);
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchicon {
  fill: currentColor;
  position: absolute;
  height: 30px;
  width: 30px;
  color: var(--text-contrast);
  left: 7px;
}

.homenav {
  height: 40px !important;
  width: 40px !important;
  cursor: pointer;
  position: fixed;
  top: 10px;
  left: 10px;
  color: var(--text);
  text-decoration: none;
  border-radius: var(--border-radius-round);
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.button {
  height: 44px;
  min-width: 62px;
  cursor: pointer;
  color: var(--text-contrast);
  text-decoration: none;
  background: var(--highlight);
  border: none;
  font-size: large;
  border-radius: var(--border-radius);
  margin: 10px 10px;
  padding: 0 16px;
  white-space: nowrap;
}

.buttonText {
  height: 44px;
  min-width: 62px;
  cursor: pointer;
  color: var(--text-contrast);
  text-decoration: none;
  background: var(--highlight);
  border: none;
  font-size: large;
  border-radius: var(--border-radius);
  margin: 10px 10px;
  padding: 20px 16px 0px 16px;
  white-space: nowrap;
}

.controls {
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: var(--background);
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 100;
}

.web {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  z-index: 10;
  background: var(--background);
  width: 100%;
  height: calc(100% - 60px);
  user-select: none;
}

.controlsIcon,
.controlsIcon img {
  margin: 0 5px 0 10px;
  height: 38px;
  width: 38px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.controlsTitle {
  color: var(--text);
  flex-grow: 1;
  margin: 0 10px 0 5px;
  padding: 7px;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.controlsButton {
  color: var(--text);
  font-size: 25px;
  cursor: pointer;
  padding: 7px;
  margin: 5px;
  display: flex;
}

.nav {
  width: 100%;
  background: transparent;
  height: 60px;
  z-index: 9;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.title {
  text-align: center;
  font-size: 30px;
  margin: 10px;
}

.desc {
  text-align: center;
  font-size: 18px;
  margin: 10px;
}

.link {
  text-decoration: none;
  color: var(--text);
}

.link:hover {
  text-decoration: underline;
}

.link:hover span[class] {
  text-decoration: underline;
}

.footer {
  color: var(--text);
  margin-top: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
  margin-bottom: 5px;
}

.footermiddle {
  margin: 0 auto;
}

.footersides {
  margin: 0 10px;
}

.web {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  z-index: 10;
  background: var(--background);
  width: 100%;
  height: calc(100% - 60px);
  user-select: none;
}

.itemscontainer {
  display: flex;
  align-self: center;
  flex-direction: column;
  padding: 0 15%;
  word-break: break-word;
}

.itemsection {
  color: var(--text);
  font-size: 30px;
  margin: 10px;
  word-break: break-all;
}

.itemtitle {
  color: var(--text);
  font-size: 22px;
  margin: 10px;
  word-break: break-all;
}

.itemtext {
  color: var(--text);
  margin: 10px;
  word-break: break-all;
}

.gitems {
  display: grid;
  grid-template-columns: repeat(auto-fill, 166px);
  justify-content: space-evenly;
  grid-gap: 2rem;
  margin: 2rem;
  margin-top: calc(2rem + 44px + 20px);
}

.gitem {
  height: 166px;
  width: 166px;
  background: var(--highlight);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 6px 0 #00000050;
  cursor: pointer;
  position: relative;
}

.gtext {
  position: absolute;
  bottom: -1.5rem;
  overflow: hidden;
  width: 166px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--text);
  text-align: center;
}

.hub {
  display: flex;
  font-size: 32px;
  font-weight: bold;
  user-select: none;
}

.hubend {
  background: var(--highlight);
  border-radius: 0.1em;
  color: var(--text-contrast);
  padding: 0 0.1em;
  margin-left: 0.1em;
}
