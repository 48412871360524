body[rounding="default"] {
  --border-radius: 6px;
  --border-radius-secondary: 30px;
  --border-radius-round: 100%;  
}

body[rounding="square"] {
  --border-radius: 6px;
  --border-radius-secondary: 6px;
  --border-radius-round: 6px;  
}

body[rounding="fancy"] {
  --border-radius: 15px;
  --border-radius-secondary: 15px;
  --border-radius-round: 15px;
}

body[rounding="circle"] {
  --border-radius: 30px;
  --border-radius-secondary: 30px;
  --border-radius-round: 100%;
}
