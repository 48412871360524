@media (prefers-color-scheme: dark) {
  body[appearance="default"] {
    --text: white;
    --text-contrast: white;
    --searchbox: #233f00;
    --highlight: #004953;
    --background: #171717;
    --placeholder: #bdc1c6;
    --font-main: "normal x-small verdana,arial,helvetica,sans-serif";
    --font-title: "normal x-small verdana,arial,helvetica,sans-serif";
    --secondary: #ffffff1a;
    --hover: #ffffff33;
  }
}

@media (prefers-color-scheme: light) {
  body[appearance="default"] {
    --text: black;
    --text-contrast: white;
    --searchbox: #233f00;
    --highlight: #3b6902;
    --background: #d8dee9;
    --placeholder: lightgray;
    --font-main: "normal x-small verdana,arial,helvetica,sans-serif";
    --font-title: "normal x-small verdana,arial,helvetica,sans-serif";
    --secondary: #0000001a;
    --hover: #00000033;
  }
}
